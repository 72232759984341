import React from 'react';
import styles from './MSDraft.module.css';

const MatchRow = ({ match }) => (
  <tr style={{ textAlign: 'center' }}>
    <td style={{ width: '10%' }}>{match.time}</td>
    <td style={{ width: '25%' }}>{match.court}</td>
    <td style={{ width: '50%' }}>
      {match.winner !== 'TBD' ? (
        <>
          <span className={styles.winner}>{match.winner}</span> -{' '}
          {match.teams.find((team) => team !== match.winner)}
        </>
      ) : (
        `${match.teams[0]} vs ${match.teams[1]}`
      )}
    </td>
    <td style={{ width: '15%' }}>
      {match.scores[0]} - {match.scores[1]}
    </td>
  </tr>
);

const DaySchedule = ({ dayMatches, date }) => (
  <div>
    <h2>{date}</h2>
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ textAlign: 'center' }}>
          <th style={{ width: '25%' }}>Time</th>
          <th style={{ width: '25%' }}>Court</th>
          <th style={{ width: '40%' }}>Teams</th>
          <th style={{ width: '15%' }}>Score</th>
        </tr>
      </thead>
      <tbody>
        {dayMatches.map((match, index) => (
          <MatchRow key={index} match={match} />
        ))}
      </tbody>
    </table>
  </div>
);

const LeagueSchedule = ({ scheduleData, leagueName }) => (
  <div>
    <h1>{leagueName}</h1>
    {Object.entries(scheduleData).map(([date, matches]) => (
      <DaySchedule key={date} dayMatches={matches} date={date} />
    ))}
  </div>
);

export default LeagueSchedule;
