import React from 'react';
// import './Home.css';
// import './Styles.css';
import SeniorDay from './Media/Senior Day.jpeg';
import Solo from './Media/Solo.jpeg';
import './index.css';

const HomePage = () => {
  return (
    <div style={{ backgroundColor: 'rgb(49, 90, 163)' }}>
      <div className='container py-3'>
        <div className='row align-items-center mb-4'>
          <div className='col-md-6'>
            <img
              src={Solo}
              alt='Kyle Butler, the founder of KB3 Elite Basketball'
              className='img-fluid rounded'
            />
          </div>
          <div className='col-md-6'>
            <h1 className='mb-3' style={{ color: 'white', fontWeight: 'bold' }}>
              About KB3 Elite Basketball
            </h1>
            <p style={{ color: 'white' }}>
              KB3 Elite Basketball was founded in 2020 by Kyle Butler. The
              mission of KB3 Elite is to provide a platform for young athletes
              to gain exposure, cultivate relationships, and build
              self-confidence all through the game of basketball.
            </p>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-md-6 order-md-2'>
            <img
              src={SeniorDay}
              alt='Coach Kyle Butler on Senior Day'
              className='img-fluid rounded'
            />
          </div>
          <div className='col-md-6 order-md-1' style={{ color: 'white' }}>
            <p>
              Kyle Butler, also referred to as “KB”, launched KB3 Elite while he
              was a student-athlete at Colby College. Running a business had
              always been a dream of his and being able to give back through the
              game he loved served as his motivating factors for starting a
              basketball program of his own.
            </p>
            <p>
              KB grew up in Mattapan, Massachusetts and was a METCO student in
              the Needham Public School system through 6th grade. He then
              completed his 7th and 8th grade years at Boston Latin Academy and
              attended The Rivers School for high school. KB was fortunate
              enough to be recruited by the basketball program at Colby where he
              played for 4 years and graduated in 2023 with a major in
              Educational Studies and a minor in Classics.
            </p>
            <p>
              It is not by accident that KB came to realize his passion for
              working with youth as he was guided by the mentorship of many
              coaches along the way. One in particular was Terrell Hollins who
              is the co-founder of Boston Elite Basketball and the head
              basketball coach at Concord Academy. Terrell was a coach to KB at
              Rivers and introduced him to his youth training business that
              opened KB’s eyes to this industry. From there, KB was able to grow
              connections with coaches and players from elementary all the way
              up to the professional level which then put him in a position to
              start up KB3 Elite and hit the ground running.
            </p>
            <p>
              In addition to directing all components of KB3 Elite, Kyle
              currently serves as the Assistant Director of Admission &
              Coordinator of Multicultural Outreach at The Fenn School in
              Concord, Massachusetts.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
