import React from 'react';
import { Table } from 'react-bootstrap';

function ScheduleTable({ schedule }) {
  // Function to determine if any game has scores
  const hasScores = Object.values(schedule).some((games) =>
    games.some((game) => game.scores)
  );

  const renderMatch = (game, idx, hasScores) => {
    const { teams, scores, winner } = game;
    return (
      <tr key={`${teams[0]}-${teams[1]}-${idx}`}>
        <td>{game.time}</td>
        <td>{`${teams[0]} vs ${teams[1]}`}</td>
        {hasScores && (
          <td>
            {scores ? (
              <div>
                {teams.map((team, index) => (
                  <div
                    key={index}
                    style={{ fontWeight: team === winner ? 'bold' : 'normal' }}
                  >
                    {team}: {scores[index]}
                  </div>
                ))}
              </div>
            ) : (
              '-'
            )}
          </td>
        )}
      </tr>
    );
  };

  return (
    <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
      {Object.keys(schedule).map((date, index) => (
        <div key={index}>
          <h3>{date}</h3>
          <Table
            striped
            bordered
            hover
            variant='dark'
            style={{ marginTop: '20px' }}
          >
            <thead>
              <tr>
                <th>Time</th>
                <th>Match</th>
                {hasScores && <th>Scores</th>}
              </tr>
            </thead>
            <tbody>
              {schedule[date].map((game, idx) =>
                renderMatch(game, idx, hasScores)
              )}
            </tbody>
          </Table>
        </div>
      ))}
    </div>
  );
}

export default ScheduleTable;
