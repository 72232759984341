import React from 'react';
import OutDoorPhoto from './Media/Home background image.jpeg';
import Pointing from './Media/6C57F575-49E9-4744-8F74-A80E3AF6E002_1_105_c.jpeg';
import Gallery from './Gallery';
import image1 from './Media/image1.jpeg';
import image2 from './Media/image2.jpeg';
import image3 from './Media/image3.jpeg';
import image4 from './Media/image4.jpeg';
import image5 from './Media/image5.jpeg';
import image6 from './Media/image6.jpeg';
import image from './Media/Coaching.jpeg';
import image7 from './Media/ball throw.jpg';
import image8 from './Media/covid.jpg';
import image9 from './Media/huddle.jpg';
import image10 from './Media/3 players.jpg';
import image11 from './Media/laugh.jpg';
import video from './Media/video_home.mp4';

export default function About() {
  return (
    <div>
      <img src={OutDoorPhoto} width={'100%'} />

      <div style={{ padding: '30px 20%', textAlign: 'center' }}>
        <h1 style={{ fontWeight: 'bold' }}>
          MAKE YOUR MARK ON AND OFF THE COURT
        </h1>
        <h3>
          KB3 Elite Basketball was founded with the mission to provide a
          platform for young athletes to gain exposure, cultivate relationships,
          and build self-confidence all through the game of basketball.
        </h3>
      </div>
      <div style={{ backgroundColor: 'rgb(49, 90, 163)', padding: '30px' }}>
        <div
          style={{
            border: '1px solid #ddd',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '400px',
            margin: 'auto',
            backgroundColor: 'white',
          }}
        >
          <h1 style={{ textAlign: 'center' }}>Meet Coach KB</h1>
          <h4 style={{ textAlign: 'center' }}>Head Coach | Founder</h4>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={Pointing}
              alt='Coach KB'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
          <p style={{ marginTop: '15px' }}>
            Kyle Butler, also referred to as “KB”, launched KB3 Elite while he
            was a student-athlete at Colby College. Running a business had
            always been a dream of his and being able to give back through the
            game he loved served as his motivating factors for starting a
            basketball program of his own.
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: 'rgb(49, 90, 163)' }}></div>

      <Gallery
        images={[
          [video, image5],
          [image, image9, image11],
          [image8],
          [image10],
        ]}
      />
    </div>
  );
}
