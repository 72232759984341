import React from 'react';
import styles from './MSDraft.module.css';
import LeagueTable from './MSDraftLeagueTable';
import LeagueSchedule from './MSDraftLeagueSchedule';
import { Container } from 'react-bootstrap';
import Champs from '../Media/2023+Champions+-+Dream+Chasers.jpg';
import Bracket from '../Media/2023+PLAYOFFS+GRAPHIC+d3.png';

function Schedule() {
  // Inline styles for centering
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const tickerMessage = 'KB3 League Standings and Schedule '.repeat(1);

  const draft_league_gold_schedule_results = {
    'July 9th': [
      {
        time: '6:00 PM',
        court: 'Court 1',
        teams: ['Team Legacy', 'Team MTD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        court: 'Court 2',
        teams: ['Team Aura', 'Ten Toes Down'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:00 PM',
        court: 'Court 3',
        teams: ['Team Hustle', 'TCB'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
    'July 16th': [
      {
        time: '7:00 PM',
        court: 'Court 1',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        court: 'Court 2',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '7:00 PM',
        court: 'Court 3',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
  };

  const draft_league_elite_schedule_results = {
    'July 11th': [
      {
        time: '4:00 PM',
        court: 'Main Court',
        teams: ['Playmakers', 'Shaq Attack'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '5:15 PM',
        court: 'Main Court',
        teams: ['Mammoths', 'Street Cred'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:30 PM',
        court: 'Main Court',
        teams: ['Splash Brothers', 'The Beasts'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '7:45 PM',
        court: 'Main Court',
        teams: ['Ballers', 'Shamrocks'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
    'July 18th': [
      {
        time: '4:00 PM',
        court: 'Main Court',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '5:15 PM',
        court: 'Main Court',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:30 PM',
        court: 'Main Court',
        teams: ['TBD', 'TBD'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
    'August 1st': [
      {
        time: '4:00 PM',
        court: 'Main Court',
        teams: ['The Beasts', 'Splash Brothers'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '5:15 PM',
        court: 'Main Court',
        teams: ['Ballers', 'Shaq Attack'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '6:30 PM',
        court: 'Main Court',
        teams: ['Street Cred', 'Playmakers'],
        scores: [0, 0],
        winner: 'TBD',
      },
      {
        time: '7:45 PM',
        court: 'Main Court',
        teams: ['Shamrocks', 'Mammoths'],
        scores: [0, 0],
        winner: 'TBD',
      },
    ],
  };

  return (
    <Container>
      {/* <h1>{tickerMessage}</h1> */}
      <div id='gold-league' style={{ color: '#D4AF37' }}>
        <h1>Gold League</h1>
        <div className='row' style={centerStyle}>
          <div className='col-4'>
            <h1>STANDINGS</h1>
          </div>
          <div className='col-md-8'>
            <LeagueTable scheduleResults={draft_league_gold_schedule_results} />
          </div>
        </div>
        <hr />
        {/* <h1 style={{ color: 'rgb(49, 90, 163)' }}></h1> */}
        <div>
          <LeagueSchedule
            scheduleData={draft_league_gold_schedule_results}
            leagueName='Gold Draft League SCHEDULE'
          />
        </div>

        <div id='playoffs' style={{ display: 'none' }}>
          <hr />
          <img
            src={Bracket}
            alt='2023 KB3 League Playoffs Bracket'
            width='100%'
          />

          <div style={{ margin: '25px 0px' }}>
            <h1 style={{ color: 'rgb(49, 90, 163)' }}>PLAYOFFS</h1>
            <h2>PLAY-IN GAMES/ROUND 1</h2>
            <div className='row'>
              <div className='col-md-2'>6:00 PM</div>
              <div className='col-md-5' style={{ textAlign: 'center' }}>
                <h4>COURT 1</h4>
                <p className={styles.playoffBox}>CARDINALS</p>
                <p className={styles.playoffBox}>ALPHAS</p>
              </div>
              <div className='col-md-5' style={{ textAlign: 'center' }}>
                <h4>COURT 2</h4>
                <p className={styles.playoffBox}>NGUNQ ALL-STARS</p>
                <p className={styles.playoffBox}>TEAM SHIFTY</p>
              </div>

              <h2>FINAL 4</h2>
              <div className='row'>
                <div className='col-md-2'>6:00 PM</div>
                <div className='col-md-5' style={{ textAlign: 'center' }}>
                  <h4>COURT 1</h4>
                  <p className={styles.playoffBox}>AFTERTHOUGHT</p>
                  <p className={styles.playoffBox}>
                    <span style={{ fontWeight: 'normal' }}>
                      LOWEST REMAINING SEED
                    </span>
                  </p>
                </div>
                <div className='col-md-5' style={{ textAlign: 'center' }}>
                  <h4>COURT 2</h4>
                  <p className={styles.playoffBox}>DREAM CHASERS</p>
                  <p className={styles.playoffBox}>
                    <span style={{ fontWeight: 'normal' }}>
                      HIGHEST REMAINING SEED
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: 'rgb(49, 90, 163)',
                border: 'solid 1px rgb(49, 90, 163)',
                borderRadius: '100px',
                padding: '30px',
                margin: '20px 0px',
                color: 'white',
              }}
            >
              <div className='row'>
                <div
                  className='col-md-6'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <h2
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      textAlign: 'center',
                    }}
                  >
                    CHAMPIONSHIP
                  </h2>
                  <h4
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    8:00 PM
                  </h4>
                </div>
                <div className='col-md-6'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <p
                      className={styles.finalsBox}
                      style={{ textAlign: 'center' }}
                    >
                      <span style={{ fontWeight: 'normal' }}>TBD</span>
                    </p>
                    <p
                      className={styles.finalsBox}
                      style={{ textAlign: 'center' }}
                    >
                      TBD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Champions /> */}
      </div>
      <hr />
      <div
        id='silver-league'
        style={{ color: 'rgb(49, 90, 163', marginBottom: '40px' }}
      >
        <h1>Elite League</h1>
        <div className='row' style={centerStyle}>
          <div className='col-4'>
            <h1>STANDINGS</h1>
          </div>
          <div className='col-md-8'>
            <LeagueTable
              scheduleResults={draft_league_elite_schedule_results}
            />
          </div>
        </div>
        <hr />
        {/* <h1 style={{ color: 'rgb(49, 90, 163)' }}></h1> */}
        <div>
          <LeagueSchedule
            scheduleData={draft_league_elite_schedule_results}
            leagueName='Elite Draft League SCHEDULE'
          />
        </div>

        <div id='playoffs' style={{ display: 'none' }}>
          <hr />
          <img
            src={Bracket}
            alt='2023 KB3 League Playoffs Bracket'
            width='100%'
          />

          <div style={{ margin: '25px 0px' }}>
            <h1 style={{ color: 'rgb(49, 90, 163)' }}>PLAYOFFS</h1>
            <h2>PLAY-IN GAMES/ROUND 1</h2>
            <div className='row'>
              <div className='col-md-2'>6:00 PM</div>
              <div className='col-md-5' style={{ textAlign: 'center' }}>
                <h4>COURT 1</h4>
                <p className={styles.playoffBox}>CARDINALS</p>
                <p className={styles.playoffBox}>ALPHAS</p>
              </div>
              <div className='col-md-5' style={{ textAlign: 'center' }}>
                <h4>COURT 2</h4>
                <p className={styles.playoffBox}>NGUNQ ALL-STARS</p>
                <p className={styles.playoffBox}>TEAM SHIFTY</p>
              </div>

              <h2>FINAL 4</h2>
              <div className='row'>
                <div className='col-md-2'>6:00 PM</div>
                <div className='col-md-5' style={{ textAlign: 'center' }}>
                  <h4>COURT 1</h4>
                  <p className={styles.playoffBox}>AFTERTHOUGHT</p>
                  <p className={styles.playoffBox}>
                    <span style={{ fontWeight: 'normal' }}>
                      LOWEST REMAINING SEED
                    </span>
                  </p>
                </div>
                <div className='col-md-5' style={{ textAlign: 'center' }}>
                  <h4>COURT 2</h4>
                  <p className={styles.playoffBox}>DREAM CHASERS</p>
                  <p className={styles.playoffBox}>
                    <span style={{ fontWeight: 'normal' }}>
                      HIGHEST REMAINING SEED
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: 'rgb(49, 90, 163)',
                border: 'solid 1px rgb(49, 90, 163)',
                borderRadius: '100px',
                padding: '30px',
                margin: '20px 0px',
                color: 'white',
              }}
            >
              <div className='row'>
                <div
                  className='col-md-6'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                  }}
                >
                  <h2
                    style={{
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      textAlign: 'center',
                    }}
                  >
                    CHAMPIONSHIP
                  </h2>
                  <h4
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    8:00 PM
                  </h4>
                </div>
                <div className='col-md-6'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <p
                      className={styles.finalsBox}
                      style={{ textAlign: 'center' }}
                    >
                      <span style={{ fontWeight: 'normal' }}>TBD</span>
                    </p>
                    <p
                      className={styles.finalsBox}
                      style={{ textAlign: 'center' }}
                    >
                      TBD
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Champions /> */}
      </div>
    </Container>
  );
}

function Champions() {
  return (
    <div style={{ margin: '25px 0px', textAlign: 'center' }}>
      <h1
        style={{
          color: 'rgb(49, 90, 163)',
          fontSize: '6em',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        2023 KB3 League Champions
      </h1>
      <img
        src={Champs}
        alt='2023 KB3 League Champions'
        width='85%'
        style={{ marginBottom: '15px' }}
      />
      <div className={styles.champsBox}>
        <h1
          style={{
            color: 'rgb(49, 90, 163)',
            fontSize: '5em',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Dream Chasers
        </h1>
        <h3
          style={{
            color: 'rgb(49, 90, 163)',
            fontSize: '3em',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Coached by Joshwa Hall
        </h3>
      </div>
    </div>
  );
}

export default Schedule;
