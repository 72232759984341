import React from 'react';
import { Container, Button } from 'react-bootstrap'; // Import Button from react-bootstrap
import KB3Banner from './Media/KB3.jpg'; // Ensure this path is correct
import { Table } from 'react-bootstrap';
import NavbarStyle from './Navbar/Navbar.module.css';

function Schedule() {
  const centerStyle = {
    // your existing centerStyle
  };

  // Text from the image for accessibility and content
  const leagueDetails = {
    name: 'KB3 Elite Basketball',
    event: 'Winter Runs',
    dates: 'Jan 8th - Feb 26th',
    days: 'Monday Nights 7:30-9PM',
    location: 'Concord Academy',
    signupLink: '/signup', // You'll need to update this with your actual signup link
  };

  return (
    <Container>
      <img
        src={KB3Banner}
        alt={`${leagueDetails.name} ${leagueDetails.event}`}
        width='100%'
        className='image-fluid'
      />

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <Button
          href='https://register.kb3elitebasketball.com' // Update with the actual signup link
          className={`${NavbarStyle['custom-nav-item']} ${NavbarStyle['nav-links-mobile']}`}
          style={{
            backgroundColor: 'rgb(49, 90, 163)',
            borderColor: 'rgb(49, 90, 163)',
            borderRadius: '100px',
            padding: '10px 30px',
            color: 'white',
            fontSize: '1.2em',
            fontWeight: 'bold',
            textDecoration: 'none',
          }}
        >
          Sign Up for Winter Runs
        </Button>
        <div>
          <LeagueDetailsTable />
        </div>
      </div>
    </Container>
  );
}

function LeagueDetailsTable() {
  // Define the details from the image
  const leagueDetails = {
    name: 'KB3 Elite Basketball',
    event: 'Winter Runs',
    dateRange: 'Jan. 8th - Feb. 26th',
    days: 'Monday Nights',
    time: '7:30-9PM',
    location: 'Concord Academy',
    locationAddress: '166 Main St, Concord, MA 01742',
    registrationLink: 'https://register.kb3league.com',
    ages: 'Grades 3rd-8th',
  };

  return (
    <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
      <h2>{leagueDetails.name}</h2>
      <h3>{leagueDetails.event}</h3>
      <Table
        striped
        bordered
        hover
        variant='dark'
        style={{ marginTop: '20px' }}
      >
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Ages</td>{' '}
            <td>{leagueDetails.ages}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Dates</td>{' '}
            {/* Added verticalAlign style */}
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.dateRange}</p>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.days}</p>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Time</td>{' '}
            {/* Added verticalAlign style */}
            <td>{leagueDetails.time}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Location</td>{' '}
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.location}</p>
              <p style={{ marginBottom: '0px' }}>
                {leagueDetails.locationAddress}
              </p>
            </td>
          </tr>

          {/* <tr>
            <td style={{ verticalAlign: 'middle' }}>Register</td>

            <td>
              <a
                href={`http://${leagueDetails.registrationLink}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Register Now
              </a>
            </td>
          </tr> */}
        </tbody>
      </Table>
    </div>
  );
}

export default Schedule;
