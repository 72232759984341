import React from 'react';
import styles from './MSDraft.module.css';
import Bracket from './../Media/Bracket.png';
import { Container } from 'react-bootstrap';
import Champs from '../Media/2023+Champions+-+Dream+Chasers.jpg';
import Gallery from './../Gallery';
import image1 from './../Media/Draft League/7287C1EB-8D18-4680-A955-04352E54408A_1_105_c.jpeg';
import image2 from './../Media/Draft League/01677CD0-9A73-431D-99F4-A74D2E30359F_1_105_c.jpeg';
import image4 from './../Media/Draft League/1222895A-2025-4532-8297-590DEABC6111_1_105_c.jpeg';
import image5 from './../Media/Draft League/A715A34E-6C24-4C67-9E11-18A8589622A2_1_105_c.jpeg';
import image6 from './../Media/Draft League/E413BF34-F750-4394-862B-E2C2AF6B83DD_1_105_c.jpeg';
import image7 from './../Media/Draft League/use.jpeg';
import image8 from './../Media/Draft League/use(1).jpeg';
import image9 from './../Media/Draft League/use(2).jpeg';
import image10 from './../Media/Draft League/use(3).jpeg';
import image11 from './../Media/Draft League/use(4).jpeg';
import image12 from './../Media/Draft League/Three.jpg';
import video3 from './../Media/Draft League/RenderedVideo.mp4';
import video2 from './../Media/Draft League/kb3.mp4';
import video1 from './../Media/Draft League/Video3.mp4';

function Schedule() {
  // Inline styles for centering`

  return (
    <>
      <div style={{ backgroundColor: 'rgb(49, 90, 163)' }}>
        <Container>
          <h3 style={{ color: 'white' }}>
            KB3 Elite Basketball elevated to a new level when we launched the
            first middle school draft league in the area: The KB3 Draft League.
            Since its inception in 2021, our summer league has been a platform
            for players in grades 6-9 to sharpen their skills and go up against
            some of the top middle school competition in New England.
          </h3>
          <hr />

          <Gallery images={[[video1, image2], [image6]]} />
        </Container>
        <Container>
          <h3 style={{ color: 'white' }}>
            The KB3 Draft League resembles the process prospects go through
            during the NBA pre-draft process. Players start with a combine where
            the coaches crafting their rosters can evaluate the players prior to
            the draft. A virtual draft is held where coaches select their teams.
            From there, teams compete in a highly competitive 6-8 week season
            that includes a regular season, playoffs, and all-star night.
          </h3>
        </Container>

        <Container>
          <Gallery images={[[image1, video2], [image5]]} />
        </Container>
      </div>

      <div style={{ backgroundColor: 'rgb(211,211,211' }}>
        <Container>
          <Champions />
          <div style={{ textAlign: 'center', marginBottom: '30px' }}>
            <img className='img-fluid' src={Bracket} />
          </div>
        </Container>
        <div style={{ backgroundColor: 'rgb(49, 90, 163)' }}>
          <Container>
            <Gallery
              images={[
                [image9],
                [image12, video3, image4],
                [image8, image10, image11],
                [image7],
              ]}
            />
          </Container>
        </div>
      </div>
    </>
  );
}

function Champions() {
  return (
    <div style={{ margin: '0px 0px 25px 0px', textAlign: 'center' }}>
      <h1
        style={{
          color: 'rgb(49, 90, 163)',
          fontSize: '6em',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        2023 KB3 Draft League Champions
      </h1>
      <img
        src={Champs}
        alt='2023 KB3 League Champions'
        width='85%'
        style={{ marginBottom: '15px' }}
      />
      <div className={styles.champsBox}>
        <h1
          style={{
            color: 'rgb(49, 90, 163)',
            fontSize: '5em',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Dream Chasers
        </h1>
        <h3
          style={{
            color: 'rgb(49, 90, 163)',
            fontSize: '3em',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          Coached by Joshwa Hall
        </h3>
      </div>
    </div>
  );
}

export default Schedule;
