import React from 'react';

const LeagueTable = ({ scheduleResults }) => {
  const calculateTeamStats = () => {
    const stats = {};
    console.log('scheduleResults:', scheduleResults);
    Object.values(scheduleResults).forEach((dateGames) => {
      console.log('dateGames:', dateGames);
      dateGames.forEach((game) => {
        if (
          game.teams[0] !== 'TBD' &&
          game.teams[1] !== 'TBD' &&
          (game.score || game.scores)
        ) {
          game.teams.forEach((team, index) => {
            if (!stats[team]) {
              stats[team] = { team, wins: 0, losses: 0, pointDiff: 0 };
            }
            const teamScore = game.score
              ? game.score[index]
              : game.scores[index];
            const opponentScore = game.score
              ? game.score[1 - index]
              : game.scores[1 - index];
            if (
              teamScore > opponentScore ||
              (game.winner && game.winner === team)
            ) {
              stats[team].wins++;
              stats[team].pointDiff += teamScore - opponentScore;
              console.log('team', team, 'wins:', stats[team].wins);
            } else {
              stats[team].losses++;
              stats[team].pointDiff += teamScore - opponentScore;
            }
          });
        }
      });
    });

    return Object.values(stats).sort((a, b) => {
      if (b.wins !== a.wins) return b.wins - a.wins;
      if (b.pointDiff !== a.pointDiff) return b.pointDiff - a.pointDiff;
      return 0;
    });
  };

  const tableData = calculateTeamStats();

  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        color: 'black',
        textAlign: 'left',
      }}
    >
      <thead>
        <tr style={{ textAlign: 'center' }}>
          <th style={{ width: '15%' }}>Ranking</th>
          <th style={{ width: '20%' }}>Team</th>
          <th style={{ width: '20%' }}>Wins</th>
          <th style={{ width: '20%' }}>Losses</th>
          <th style={{ width: '25%' }}>Point Differential</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={row.team}>
            <td>{index + 1}</td>
            <td>{row.team}</td>
            <td>{row.wins}</td>
            <td>{row.losses}</td>
            <td>{row.pointDiff}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LeagueTable;
