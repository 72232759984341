import React from 'react';

function getFileExtension(url) {
  return url.slice(((url.lastIndexOf('.') - 1) >>> 0) + 2);
}

function Gallery({ images }) {
  const renderMedia = (url, rowIndex, colIndex) => {
    const extension = getFileExtension(url).toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return (
          <img
            src={url}
            className='img-fluid w-100'
            style={{ padding: '10px' }}
            alt={``}
          />
        );
      case 'mp4':
      case 'webm':
      case 'ogg':
        return (
          <video
            className='img-fluid w-100'
            style={{ padding: '10px' }}
            autoPlay
            muted
            loop
            controlsList='nofullscreen'
            playsInline
          >
            <source src={url} type={`video/${extension}`} />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <div>Unsupported format</div>;
    }
  };

  return (
    <div className='container'>
      {images.map((row, rowIndex) => (
        <div key={rowIndex} className='row'>
          {row.map((url, colIndex) => (
            <div key={colIndex} className={`col-md-${12 / row.length}`}>
              {renderMedia(url, rowIndex, colIndex)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Gallery;
