import React, { useState, useEffect, useRef } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import video1 from './Media/Ben Paradis.mov';
import video2 from './Media/kb3_final.mp4';
import Gallery from './Gallery';
import image7 from './Media/image7.jpeg';
import image8 from './Media/image8.jpeg';
import image9 from './Media/IMG_0452.jpg';

export default function TrainingPage() {
  const [index, setIndex] = useState(0);
  const videoRefs = useRef([React.createRef(), React.createRef()]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleVideoEnd = () => {
    const nextIndex = (index + 1) % videoRefs.current.length;
    setIndex(nextIndex);
  };

  useEffect(() => {
    // When the index changes, restart the video at that index
    if (videoRefs.current[index] && videoRefs.current[index].current) {
      videoRefs.current[index].current.load();
    }
  }, [index]);

  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        <Carousel.Item>
          <video
            ref={videoRefs.current[0]}
            className='d-block w-100'
            controls
            autoPlay
            muted
            playsInline
            onEnded={handleVideoEnd}
          >
            <source src={video1} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <Carousel.Caption>
            <h3>Training Ben Paradis</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <video
            ref={videoRefs.current[1]}
            className='d-block w-100'
            controls
            autoPlay
            playsInline
            muted
            onEnded={handleVideoEnd}
          >
            <source src={video2} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
          <Carousel.Caption>
            <h3>Training Alex Stoddard and Robbie Stankard</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div
        style={{
          backgroundColor: 'rgb(49, 90, 163)',
          margin: '0px',
          padding: '20px',
        }}
      >
        <Container className='my-5'>
          <Row>
            <Col md={6} className=' d-flex align-items-center'>
              <p
                style={{
                  fontSize: '2.5em',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  whiteSpace: 'pre-wrap',
                  transitionTimingFunction: 'ease',
                  transitionDuration: '0.8s',
                  transitionDelay: '0.234783s',
                  color: 'white',
                }}
              >
                ENHANCING CONFIDENCE AND DECISION-MAKING ON AND OFF THE COURT
              </p>
            </Col>
            <Col
              md={6}
              style={{
                fontSize: '1.2em',
                color: 'white',
              }}
            >
              <p>
                KB3 Elite Basketball excels in empowering players at all levels,
                focusing on personal growth and decision-making skills through
                individual, small group, and game-time training sessions. We
                emphasize the development of quick, strategic thinking on the
                court. Our collaborative approach with players, parents, and
                coaches involves creating customized training programs that not
                only polish basketball skills but also build confidence, enhance
                game-time decision-making, and promote a positive mindset in
                each athlete.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Gallery images={[[image7], [image8, image9]]} />
        </Container>
      </div>
    </>
  );
}
