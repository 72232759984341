import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Coaching from './Media/phone.jpeg';

export default function Contact() {
  return (
    <div style={{ backgroundColor: 'rgb(56, 56, 56)', color: 'white' }}>
      <Container>
        <Row className='align-items-center' style={{ height: '80vh' }}>
          <Col md={6} xs={12} className=''>
            <h1 style={{ fontSize: '4em' }}>CONTACT KYLE</h1>
            <p>
              Email:{' '}
              <a
                href='mailto:kyle3butler@gmail.com'
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                kyle3butler@gmail.com
              </a>
            </p>
            <p>
              Phone:{' '}
              <a
                href='tel:+617-947-1519'
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                (617)-947-1519
              </a>
            </p>
            <p>
              Instagram:
              <a
                href='https://www.instagram.com/kb3elitebasketball/'
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                @kb3elitebasketball
              </a>
            </p>
          </Col>
          <Col md={6} xs={12} className='text-center'>
            <img
              src={Coaching}
              alt='Coaching'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
