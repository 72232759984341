import React from 'react';
import { Container, Table } from 'react-bootstrap';
import KB3Banner from './../Media/summer-classic.jpg';
import NavbarStyle from '../Navbar/Navbar.module.css';
import LeagueTable from './HSDraftLeagueTable';
import ScheduleTable from './ScheduleCollegeTable';

function Schedule() {
  const leagueDetails = {
    name: 'Boston Elite Summer Classic',
    event: 'Summer Classic',
    dateRange: 'June 12th - August 21st',
    days: 'Wednesday Nights',
    time: '5-10PM',
    location: 'Brandeis University',
    locationDetails: 'The Gosman Sports and Convocation Center',
    locationAddress: '415 South St, Waltham, MA 02453',
    registrationLink: 'https://register.kb3league.com',
    ages: 'College',
  };

  const schedule = {
    'July 10th': [
      {
        time: '5:00 PM',
        teams: ['Seacoast', 'Maine'],
        scores: [76, 61],
        winner: 'Seacoast',
      },
      {
        time: '6:00 PM',
        teams: ['Rams', 'Raiders'],
        scores: [63, 61],
        winner: 'Rams',
      },
      {
        time: '7:00 PM',
        teams: ['Hawks', 'Camels'],
        scores: [64, 69],
        winner: 'Camels',
      },
      {
        time: '8:00 PM',
        teams: ['Leopards', 'Lasers'],
        scores: [49, 52],
        winner: 'Lasers',
      },
      {
        time: '9:00 PM',
        teams: ['Tech', 'Goats'],
        scores: [53, 77],
        winner: 'Goats',
      },
    ],
    'July 17th': [
      {
        time: '5:00 PM',
        teams: ['Rams', 'Leopards'],
        scores: [75, 74],
        winner: 'Rams',
      },
      {
        time: '6:00 PM',
        teams: ['Goats', 'Camels'],
        scores: [52, 65],
        winner: 'Camels',
      },
      {
        time: '7:00 PM',
        teams: ['Tech', 'Hawks'],
        scores: [90, 73],
        winner: 'Tech',
      },
      {
        time: '8:00 PM',
        teams: ['Maine', 'Lasers'],
        scores: [52, 61],
        winner: 'Lasers',
      },
      {
        time: '9:00 PM',
        teams: ['Seacoast', 'Raiders'],
        scores: [100, 77],
        winner: 'Seacoast',
      },
    ],
    'July 24th': [
      {
        time: '5:00 PM',
        teams: ['Lasers', 'Seacoast'],
        scores: [73, 80],
        winner: 'Seacoast',
      },
      {
        time: '6:00 PM',
        teams: ['Goats', 'Raiders'],
        scores: [66, 59],
        winner: 'Goats',
      },
      {
        time: '7:00 PM',
        teams: ['Camels', 'Leopards'],
        scores: [66, 65],
        winner: 'Camels',
      },
      {
        time: '8:00 PM',
        teams: ['Rams', 'Tech'],
        scores: [55, 63],
        winner: 'Tech',
      },
      {
        time: '9:00 PM',
        teams: ['Hawks', 'Maine'],
        scores: [79, 71],
        winner: 'Hawks',
      },
    ],
    'July 31st': [
      {
        time: '5:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '6:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '7:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '8:00 PM',
        teams: ['TBD', 'TBD'],
      },
      {
        time: '9:00 PM',
        teams: ['TBD', 'TBD'],
      },
    ],
  };

  const pre_break_schedule = {
    'June 12th': [
      {
        time: '5:00 PM',
        teams: ['Seacoast', 'Rams'],
        scores: [76, 61],
        winner: 'Seacoast',
      },
      {
        time: '6:00 PM',
        teams: ['Leopards', 'Goats'],
        scores: [49, 35],
        winner: 'Leopards',
      },
      {
        time: '7:00 PM',
        teams: ['Lasers', 'Hawks'],
        scores: [74, 70],
        winner: 'Lasers',
      },
      {
        time: '8:00 PM',
        teams: ['Tech', 'Raiders'],
        scores: [59, 57],
        winner: 'Tech',
      },
    ],
    'June 19th': [
      {
        time: '5:00 PM',
        teams: ['Raiders', 'Hawks'],
        scores: [67, 59],
        winner: 'Raiders',
      },
      {
        time: '6:00 PM',
        teams: ['Rams', 'Camels'],
        scores: [61, 56],
        winner: 'Rams',
      },
      {
        time: '7:00 PM',
        teams: ['Tech', 'Maine'],
        scores: [64, 53],
        winner: 'Tech',
      },
      {
        time: '8:00 PM',
        teams: ['Goats', 'Lasers'],
        scores: [77, 71],
        winner: 'Goats',
      },
      {
        time: '9:00 PM',
        teams: ['Seacoast', 'Leopards'],
        scores: [80, 60],
        winner: 'Seacoast',
      },
    ],
    'June 26th': [
      {
        time: '5:00 PM',
        teams: ['Leopards', 'Hawks'],
        scores: [85, 66],
        winner: 'Leopards',
      },
      {
        time: '6:00 PM',
        teams: ['Seacoast', 'Goats'],
        scores: [60, 50],
        winner: 'Seacoast',
      },
      {
        time: '7:00 PM',
        teams: ['Lasers', 'Rams'],
        scores: [76, 62],
        winner: 'Lasers',
      },
      {
        time: '8:00 PM',
        teams: ['Raiders', 'Maine'],
        scores: [76, 54],
        winner: 'Raiders',
      },
      {
        time: '9:00 PM',
        teams: ['Camels', 'Tech'],
        scores: [85, 80],
        winner: 'Camels',
      },
    ],
  };

  return (
    <Container>
      <img
        src={KB3Banner}
        alt={`${leagueDetails.name} ${leagueDetails.event}`}
        width='100%'
        className='image-fluid'
      />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <div>
          <LeagueDetailsTable leagueDetails={leagueDetails} />
        </div>
        <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
          <h2>Team Standings</h2>
          <LeagueTable
            scheduleResults={{ ...pre_break_schedule, ...schedule }}
          />
          {/* <LeagueTable scheduleResults={[...pre_break_schedule, ...schedule]} /> */}
        </div>
        <div>
          <h2>Schedule</h2>
          <ScheduleTable schedule={pre_break_schedule} />
          <div style={{ margin: '50px 0' }}>
            <h3>July 3rd</h3>
            <h5>No games - July 4th break</h5>
          </div>
          <ScheduleTable schedule={schedule} />
        </div>
      </div>
    </Container>
  );
}

function LeagueDetailsTable(props) {
  const { leagueDetails } = props;
  console.log('leagueDetails:', leagueDetails);

  return (
    <div style={{ margin: '20px', padding: '20px', textAlign: 'center' }}>
      <h2>{leagueDetails.name}</h2>
      <Table
        striped
        bordered
        hover
        variant='dark'
        style={{ marginTop: '20px' }}
      >
        <tbody>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Ages</td>
            <td>{leagueDetails.ages}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Dates</td>
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.dateRange}</p>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.days}</p>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Time</td>
            <td>{leagueDetails.time}</td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>Location</td>
            <td>
              <p style={{ marginBottom: '0px' }}>{leagueDetails.location}</p>
              <p style={{ marginBottom: '0px' }}>
                {leagueDetails.locationDetails}
              </p>
              <p style={{ marginBottom: '0px' }}>
                {leagueDetails.locationAddress}
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ verticalAlign: 'middle' }}>League Commissioner</td>
            <td>
              <p style={{ marginBottom: '0px' }}>Kyle Butler</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Schedule;
