import React from 'react';
import Navbar from './Navbar/Navbar';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Events from './Events';
import Draft from './MS Draft League/MSDraftInSeason';
import DraftLeagueInfo from './MS Draft League/MSDraftLeagueInfo';
// import Winter from './WinterRuns';
import Winter from './Winter';
import Summer from './CollegeRuns/SummerCollegeRuns';
import Training from './Training';
import Footer from './Navbar/Footer';

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        <Route path='/contact' element={<Contact />} />

        <Route path='/training' element={<Training />} />
        <Route path='/draft-league' element={<Draft />} />
        {/* <Route path='/draft-league-in-season' element={<Draft />} /> */}
        {/* <Route path='/draft-league' element={<DraftLeagueInfo />} /> */}
        <Route path='/events' element={<Events />} />
        <Route path='/about' element={<About />} />
        <Route path='/winter-runs' element={<Winter />} />
        <Route path='/summer-classic' element={<Summer />} />
        <Route exact path='/' element={<Home />} />
        <Route path='/*' element={<Navigate to='/' replace />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
