import React from 'react';
import styles from './Footer.module.css'; // Import the CSS module for the footer
import Logo from '../Media/Logo.png';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        {' '}
        {/* This div wraps the text content */}
        <div className={styles.updateSection}>
          <h1 className={styles.footerHeading}>STAY UPDATED</h1>
          <p>
            Follow{' '}
            <a
              href='https://www.instagram.com/kb3elitebasketball/'
              className={styles.linkWhite}
            >
              @kb3elitebasketball
            </a>{' '}
            on Instagram and{' '}
            <a
              href='https://twitter.com/kb3elitebb?s=21&t=u3fBjjVcLE5vsAOXq9a1mg'
              className={styles.linkWhite}
            >
              @kb3elitebb
            </a>{' '}
            on X (formerly Twitter).
          </p>
        </div>
        <hr className={styles.customHr} />
        <div className={styles.contactSection}>
          <h1 className={styles.footerHeading}>CONTACT</h1>
          <p>Call/text: (617) 947-1519</p>
          <p>
            Email:{' '}
            <a href='mailto:kyle3butler@gmail.com'>kyle3butler@gmail.com</a>
          </p>
        </div>
      </div>
      <div className={styles.logoContainer}>
        {' '}
        {/* This div is for the logo */}
        <img
          src={Logo}
          className={styles.contactImage}
          alt='KB3 Elite Basketball Logo'
        />
      </div>
    </div>
  );
};

export default Footer;
