import React from 'react';
import { Container } from 'react-bootstrap';

export default function Events() {
  return (
    <Container>
      <h1 style={{ minHeight: '90vh' }}>Events Coming Soon</h1>
    </Container>
  );
}
