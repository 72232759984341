import React from 'react';

const LeagueTable = ({ scheduleResults }) => {
  const calculateTeamStats = () => {
    const stats = {};
    Object.values(scheduleResults).forEach((dateGames) => {
      dateGames.forEach((game) => {
        game.teams.forEach((team, index) => {
          if (team !== 'TBD') {
            if (!stats[team]) {
              stats[team] = { team, wins: 0, losses: 0, pointDiff: 0 };
            }
            if (game.winner === team) {
              stats[team].wins++;
              stats[team].pointDiff +=
                game.scores[index] - game.scores[1 - index];
            } else if (game.winner !== 'TBD') {
              stats[team].losses++;
              stats[team].pointDiff +=
                game.scores[index] - game.scores[1 - index];
            }
          }
        });
      });
    });
    return Object.values(stats).sort((a, b) => {
      if (b.wins !== a.wins) return b.wins - a.wins;
      if (b.pointDiff !== a.pointDiff) return b.pointDiff - a.pointDiff;
      return 0;
    });
  };

  const tableData = calculateTeamStats();

  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        color: 'black',
        textAlign: 'left',
      }}
    >
      <thead>
        <tr>
          <th>Ranking</th>
          <th>Team</th>
          <th>Wins</th>
          <th>Losses</th>
          <th>Point Differential</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => (
          <tr key={row.team}>
            <td>{index + 1}</td>
            <td>{row.team}</td>
            <td>{row.wins}</td>
            <td>{row.losses}</td>
            <td>{row.pointDiff}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LeagueTable;
